import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { TerminArt } from "../../details/details.page";
import { TermineService } from "../../api/services/termine.service";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Component({
  selector: "app-termin-weitersetzen-kommentar",
  templateUrl: "./termin-weitersetzen-kommentar.component.html",
  styleUrls: ["./termin-weitersetzen-kommentar.component.scss"],
})
export class TerminWeitersetzenKommentarComponent implements OnInit {
  @Input({ required: true }) aktId: number;
  @Input({ required: true }) aktTitle: number;
  termin: any;
  kommentarFormGroup: UntypedFormGroup;
  fetchingKundentermin: boolean = false;
  isSaving: boolean = false;
  submitted: boolean = false;
  terminId: number = null;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private alertController: AlertController,
    private termineService: TermineService,
    private oidcSecurityService: OidcSecurityService,
    private toastController: ToastController,
  ) {}

  ngOnInit() {
    this.fetchKundentermin();

    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    this.kommentarFormGroup = this.fb.group({
      BemerkungNeu: [null, Validators.required],
      DatumNeu: [currentDate, [Validators.required, this.dateValidator()]],
    });
  }

  cancelButton() {
    if (this.kommentarFormGroup.dirty) {
      this.abbrechenBestaetigenDialogAnzeigen();
    } else {
      this.modalController.dismiss(null, "cancel");
    }
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const maxDate = new Date(today);
      maxDate.setDate(today.getDate() + 42);

      const inputDate = new Date(control.value);
      inputDate.setHours(0, 0, 0, 0);

      if (inputDate < today) {
        return {
          customDateError: "Datum darf nicht in der Vergangenheit liegen!",
        };
      }

      return null;
    };
  }

  async submit() {
    this.submitted = true;
    if (this.kommentarFormGroup.invalid) {
      this.kommentarFormGroup.markAllAsTouched();
      return;
    }
    if (!this.terminId) {
      const toast = await this.toastController.create({
        message: "Fehler beim Laden des Termins. Speichern fehlgeschlagen.",
        duration: 5000,
      });
      toast.present();
      this.fetchKundentermin();
      return;
    }

    const alert = await this.alertController.create({
      header: "Weitersetzen abschließen",
      message: "Der Termin wird weitergesetzt und ein Kommentar wird erstellt.",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-secondary",
          text: "Abschließen",
          handler: async () => {
            this.isSaving = true;
            this.terminWeitersetzen()
              .then(() => {
                this.isSaving = false;
                this.modalController.dismiss(null, "confirm");
              })
              .catch(async (error: Error) => {
                this.isSaving = false;
                const toast = await this.toastController.create({
                  message: error.message,
                  duration: 5000,
                });
                toast.present();
              });
          },
        },
      ],
    });

    await alert.present();
  }

  async terminWeitersetzen() {
    const dateValue = this.kommentarFormGroup.get("DatumNeu").value;

    return new Promise<void>((resolve, reject) => {
      this.termineService
        .termineOdataKundenterminWeitersetzenCreate({
          token: this.oidcSecurityService.getToken(),
          BemerkungNeu: this.kommentarFormGroup.get("BemerkungNeu").value,
          DatumNeu: `${dateValue}T00:00:00`,
          TerminId: this.terminId,
        })
        .subscribe({
          next: (result) => {
            resolve();
          },
          error: async (error) => {
            const message = `Fehler beim Weitersetzen des Kundentermins!`;
            reject(new Error(message));
          },
        });
    });
  }

  async abbrechenBestaetigenDialogAnzeigen() {
    const alert = await this.alertController.create({
      header: "Ungespeicherte Änderungen vorhanden",
      message:
        "Wollen Sie alle Änderungen am Kommentar verwerfen und das Fenster schließen?",
      buttons: [
        {
          cssClass: "color-primary",
          text: "Abbrechen",
          role: "cancel",
        },
        {
          cssClass: "color-danger",
          text: "Verwerfen",
          handler: () => {
            this.modalController.dismiss(null, "cancel");
          },
        },
      ],
    });
    await alert.present();
  }

  fetchKundentermin() {
    this.fetchingKundentermin = true;
    this.termineService
      .termineOdataAktKundenterminCreate({
        token: this.oidcSecurityService.getToken(),
        akt_id: this.aktId,
      })
      .subscribe((response: any) => {
        if (response.length > 1) {
          this.termin = response.find((termin) => !termin.OK);
        } else {
          this.termin = response[0];
        }
        this.terminId = this.termin?.TerminID;
        this.fetchingKundentermin = false;
      });
  }
}
