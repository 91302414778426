import { NgModule } from "@angular/core";
import { FormControlPipe } from "./pipes/form-control.pipe";
import { HeaderComponent } from "./header/header.component";
import { IonicModule } from "@ionic/angular";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { RouterLink } from "@angular/router";
import { HazardOverviewComponent } from "./hazard-overview/hazard-overview.component";
import { MatTooltip } from "@angular/material/tooltip";
import { FileOverviewComponent } from "./file-overview/file-overview.component";
import { NgxDatatableModule } from "@siemens/ngx-datatable";
import { MatProgressBar } from "@angular/material/progress-bar";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableModule,
} from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";
import { MatSortModule } from "@angular/material/sort";
import { TerminBestaetigenComponent } from "./termin-bestaetigen/termin-bestaetigen.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { TerminHinzufuegenComponent } from "./termin-hinzufuegen/termin-hinzufuegen.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { AktortBearbeitenComponent } from "./aktort-bearbeiten/aktort-bearbeiten.component";
import { KontaktdatenAnsichtModalComponent } from "./kontaktdaten-ansicht-modal/kontaktdaten-ansicht-modal.component";
import { EmailVersendenComponent } from "./email-versenden/email-versenden.component";
import { TerminWeitersetzenKommentarComponent } from "./termin-weitersetzen-kommentar/termin-weitersetzen-kommentar.component";
import { KontaktdatenComponent } from "./kontaktdaten/kontaktdaten.component";

@NgModule({
  declarations: [
    FormControlPipe,
    HeaderComponent,
    HazardOverviewComponent,
    FileOverviewComponent,
    TerminBestaetigenComponent,
    TerminHinzufuegenComponent,
    AktortBearbeitenComponent,
    EmailVersendenComponent,
    KontaktdatenComponent,
    KontaktdatenAnsichtModalComponent,
    TerminWeitersetzenKommentarComponent,
  ],
  exports: [
    FormControlPipe,
    HeaderComponent,
    HazardOverviewComponent,
    FileOverviewComponent,
    TerminBestaetigenComponent,
    TerminHinzufuegenComponent,
    AktortBearbeitenComponent,
    EmailVersendenComponent,
    KontaktdatenComponent,
    KontaktdatenAnsichtModalComponent,
    TerminWeitersetzenKommentarComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    NgOptimizedImage,
    RouterLink,
    MatTooltip,
    NgxDatatableModule,
    MatProgressBar,
    MatTableModule,
    MatHeaderCell,
    MatCell,
    MatIconModule,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    MatCellDef,
    MatHeaderCellDef,
    MatColumnDef,
    CdkDrag,
    CdkDropList,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinner,
    NgSelectModule,
  ],
})
export class UtilModule {}
