export function getLowerCaseInputValue(input: string): string {
  return input ? input.toLowerCase() : null;
}

export function getStringFilterConditionForDatatable(
  datavalue: string,
  filtervalue: string,
): boolean {
  return (
    (datavalue !== null &&
      filtervalue !== null &&
      datavalue.toLowerCase().indexOf(filtervalue) !== -1) ||
    !filtervalue
  );
}

export function replaceFileExtensionConditionally(fileName: string): string {
  if (fileName.endsWith(".pms")) {
    return fileName.slice(0, -4) + ".eml";
  }

  return fileName;
}

export function isAktArtTechnik(aktArt: string): boolean {
  const technikSparten: string[] = [
    "Blitzschadenprüfstelle A",
    "E-Geräteschaden",
    "Maschinenbruch",
    "Verkehrstechnik",
  ];
  return technikSparten.includes(aktArt);
}

export function getKontaktBezeichnungByKuerzel(kuerzel: string): string {
  switch (kuerzel) {
    case "AST":
      return "AnspruchstellerIn";
    case "Ansprechpartner":
      return "Ansprechpartner";
    case "FCA":
      return "FCA";
    case "SB2":
      return "Schadenregulierer";
    case "VN":
      return "VersicherungsnehmerIn";
    case "VU":
      return "Versicherung";
    case "VUSB":
      return "SachbearbeiterIn";
    case "Vermittler":
      return "VermittlerIn";
    default:
      return "";
  }
}
