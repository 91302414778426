import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { isNotNullOrUndefined } from "codelyzer/util/isNotNullOrUndefined";
import { IonModal, Platform, ToastController } from "@ionic/angular";
import { KontaktDetail } from "../../api/models/kontakt-detail";
import { OverlayEventDetail } from "@ionic/core/components";
import { KontaktService } from "../../api/services/kontakt.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { KontaktDetailUpdate } from "../../api/models/kontakt-detail-update";

@Component({
  selector: "app-kontaktdaten",
  templateUrl: "./kontaktdaten.component.html",
  styleUrls: ["./kontaktdaten.component.scss"],
})
export class KontaktdatenComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  @Input()
  akt_id: number;

  @Input()
  enableEdit: boolean;

  @Input()
  instance: KontaktDetail;

  @Input()
  fca: any;

  @Input()
  titel: string;

  @Input()
  auftrag: string;

  @Input()
  anspruchsteller: string;

  @Input()
  schadensnummer: string;

  editformGroup: UntypedFormGroup;

  emailLink;

  constructor(
    private platform: Platform,
    private kontaktService: KontaktService,
    private toastController: ToastController,
    private oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit() {
    if (isNotNullOrUndefined(this.instance)) {
      this.getEmailLink();
    }
  }

  getEmailLink() {
    const body = "&body=Sehr%20geehrte(r)";
    let subject = "";
    subject += "Betreffend Auftrag [" + this.auftrag + "] ";
    subject += "Schadennr. [" + this.schadensnummer + "] ";
    subject += "Anspruchsteller [" + this.anspruchsteller + "]";
    subject = encodeURI(subject);
    let bcc = "";
    if (isNotNullOrUndefined(this.fca)) {
      bcc += "?bcc=" + this.fca.Email;
    }
    this.emailLink =
      "mailto:" +
      this.instance.Email +
      bcc +
      "&subject=" +
      subject +
      "&body=Sehr%20geehrte(r)";
  }

  openGMaps(Address: any) {
    const baseUrlG = "https://www.google.com/maps/place/";
    const baseUrlA = "maps://?address=";
    const urlEncodedAddress = encodeURI(Address);

    if (this.platform.is("ios")) {
      window.open(baseUrlA + urlEncodedAddress, "_system");
    } else if (this.platform.is("android")) {
      window.open(baseUrlG + urlEncodedAddress, "_system");
    } else {
      window.open(baseUrlG + urlEncodedAddress, "_blank").focus();
    }
  }

  openEditModal() {
    this.editformGroup = new UntypedFormGroup({
      Id: new UntypedFormControl({
        value: "",
        disabled: true,
      }),
      Email: new UntypedFormControl(),
      Mobile: new UntypedFormControl(),
      Name: new UntypedFormControl({
        value: "",
        disabled: true,
      }),
      Name2: new UntypedFormControl({
        value: "",
        disabled: true,
      }),
      Ort: new UntypedFormControl(),
      Phone: new UntypedFormControl(),
      Plz: new UntypedFormControl(),
      Strasse: new UntypedFormControl(),
      Vorname: new UntypedFormControl({
        value: "",
        disabled: true,
      }),
      Art: new UntypedFormControl({
        value: "",
        disabled: true,
      }),
    });
    this.editformGroup.setValue(this.instance);
    this.modal.present();
  }

  cancelEditModal() {
    this.modal.dismiss(null, "cancel");
  }

  confirmEditModal() {
    if (this.editformGroup.dirty) {
      const elementToBeSaved = {} as KontaktDetailUpdate;
      elementToBeSaved.Id = this.instance.Id;
      elementToBeSaved.token = this.oidcSecurityService.getToken();
      elementToBeSaved.akt_id = this.akt_id.toString();
      if (this.editformGroup.controls.Email?.dirty)
        elementToBeSaved.Email = this.editformGroup.controls.Email.value;
      if (this.editformGroup.controls.Mobile?.dirty)
        elementToBeSaved.Mobile = this.editformGroup.controls.Mobile.value;
      if (this.editformGroup.controls.Ort?.dirty)
        elementToBeSaved.Ort = this.editformGroup.controls.Ort.value;
      if (this.editformGroup.controls.Phone?.dirty)
        elementToBeSaved.Phone = this.editformGroup.controls.Phone.value;
      if (this.editformGroup.controls.Plz?.dirty)
        elementToBeSaved.Plz = this.editformGroup.controls.Plz.value;
      if (this.editformGroup.controls.Strasse?.dirty)
        elementToBeSaved.Strasse = this.editformGroup.controls.Strasse.value;

      this.saveKontakt(elementToBeSaved);
    }
    this.modal.dismiss(this.instance, "confirm");
  }

  onWillDismissEditModal(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail>;
    if (ev.detail.role === "confirm") {
      console.log("Updated Instance:", ev.detail.data);
    }
  }

  saveKontakt(kontakt: KontaktDetailUpdate) {
    kontakt.token = this.oidcSecurityService.getToken();
    this.kontaktService.kontaktSaveCreate(kontakt).subscribe((res) => {
      this.toastAnzeigen("Kontakt gespeichert");
      for (const entry in kontakt) {
        if (entry !== "token" && entry !== "Id" && entry !== "akt_id") {
          this.instance[entry] = kontakt[entry];
        }
      }
    });
  }

  async toastAnzeigen(message: string) {
    const toast = await this.toastController.create({
      message,
      position: "top",
      duration: 5000,
      buttons: [
        {
          text: "OK",
          role: "cancel",
        },
      ],
    });
    await toast.present();
  }
}
