import { Component, Input, OnInit } from "@angular/core";
import { AktService } from "../../api/services/akt.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { OdataAktOrtResponse } from "../../api/models/odata-akt-ort-response";
import { ModalController } from "@ionic/angular";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { AktOrtArt } from "../enums/global-enums";

@Component({
  selector: "app-aktort-bearbeiten",
  templateUrl: "./aktort-bearbeiten.component.html",
  styleUrls: ["./aktort-bearbeiten.component.scss"],
})
export class AktortBearbeitenComponent implements OnInit {
  @Input() aktId;
  @Input() aktOrtArt: AktOrtArt;
  aktOrt: OdataAktOrtResponse;
  aktOrtBearbeitenFormGroup: UntypedFormGroup;
  isSaving: boolean = false;
  noBesichtigungExists: boolean = false;

  constructor(
    private aktService: AktService,
    private oidcSecurityService: OidcSecurityService,
    private modalController: ModalController,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.aktOrtBearbeitenFormGroup = this.fb.group({
      PLZ: [null],
      Ort: [null],
      Strasse: [null],
    });
    this.aktOrtBearbeitenFormGroup.disable();
    this.aktService
      .aktAktortOdataRetrieveCreate({
        token: this.oidcSecurityService.getToken(),
        akt_id: this.aktId,
      })
      .subscribe((response: OdataAktOrtResponse[]) => {
        this.aktOrt = response.find((ort) => ort.Art === this.aktOrtArt);
        if (!this.aktOrt && this.aktOrtArt === AktOrtArt.Besichtigung) {
          this.aktOrt = response.find(
            (ort) => ort.Art === AktOrtArt.Schadenort,
          );
          this.noBesichtigungExists = true;
        }
        this.aktOrtBearbeitenFormGroup.patchValue({
          PLZ: this.aktOrt?.PLZ,
          Ort: this.aktOrt?.Ort,
          Strasse: this.aktOrt?.Strasse,
        });
        this.aktOrtBearbeitenFormGroup.enable();
      });
  }

  cancelButton() {
    this.modalController.dismiss(null, "cancel");
  }

  submit() {
    if (this.aktOrtBearbeitenFormGroup.invalid || !this.aktOrt) {
      this.aktOrtBearbeitenFormGroup.markAllAsTouched();
      return;
    }
    this.save();
  }

  async save() {
    this.isSaving = true;
    if (this.aktOrtArt === AktOrtArt.Besichtigung) {
      const ortForBesichtigung = this.noBesichtigungExists ? null : this.aktOrt;
      this.aktService
        .aktAktortOdataBesichtigungsortChangeCreate({
          akt_id: this.aktId,
          token: this.oidcSecurityService.getToken(),
          Oid: ortForBesichtigung?.Oid,
          Nummer: 1,
          Art: this.aktOrtArt,
          PLZ: this.aktOrtBearbeitenFormGroup.get("PLZ").value,
          Ort: this.aktOrtBearbeitenFormGroup.get("Ort").value,
          Strasse: this.aktOrtBearbeitenFormGroup.get("Strasse").value,
          Bemerkung: ortForBesichtigung?.Bemerkung,
          AktId: this.aktId,
        })
        .subscribe(() => {
          this.isSaving = false;
          this.modalController.dismiss(null, "confirm");
        });
    } else {
      this.aktService
        .aktAktortOdataPatchCreate({
          akt_id: this.aktId,
          token: this.oidcSecurityService.getToken(),
          Oid: this.aktOrt?.Oid,
          Nummer: this.aktOrt?.Nummer,
          Art: this.aktOrt?.Art,
          PLZ: this.aktOrtBearbeitenFormGroup.get("PLZ").value,
          Ort: this.aktOrtBearbeitenFormGroup.get("Ort").value,
          Strasse: this.aktOrtBearbeitenFormGroup.get("Strasse").value,
          Bemerkung: this.aktOrt?.Bemerkung,
          AktId: this.aktOrt?.AktId,
        })
        .subscribe(() => {
          this.isSaving = false;
          this.modalController.dismiss(null, "confirm");
        });
    }
  }
}
