// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  white-space: break-spaces;
}

.textbaustein-name-padding {
  padding-bottom: 8px;
  padding-left: 7px;
  padding-right: 7px;
}

.korrektur-vorschau-container {
  padding-left: 10px;
  padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/textvorlage/textvorlage.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF","sourcesContent":["ion-button {\n  white-space: break-spaces;\n}\n\n.textbaustein-name-padding {\n  padding-bottom: 8px;\n  padding-left: 7px;\n  padding-right: 7px;\n}\n\n.korrektur-vorschau-container {\n  padding-left: 10px;\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
