import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guard/auth.guard";
import { AbloeseResolve } from "./resolve/abloese.resolve";
import { MailmanagerResolve } from "./resolve/mailmanager.resolve";

const routes: Routes = [
  { path: "index.html", redirectTo: "/uebersicht" },
  {
    path: "uebersicht",
    loadChildren: () =>
      import("./uebersicht/uebersicht.module").then(
        (m) => m.UebersichtPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
    pathMatch: "prefix",
  },
  {
    path: "user",
    loadChildren: () =>
      import("./user/user.module").then((m) => m.UserPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "kundenstatusliste",
    loadChildren: () =>
      import("./kundenstatusliste/kundenstatusliste.module").then(
        (m) => m.KundenprozesseListPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "aktsuche",
    loadChildren: () =>
      import("./aktsuche/aktsuche.module").then((m) => m.AktsuchePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "mailmanager",
    loadChildren: () =>
      import("./mailmanager/mailmanager.module").then(
        (m) => m.MailmanagerPageModule,
      ),
    canActivate: [AuthGuard],
    resolve: {
      userData: MailmanagerResolve,
    },
  },
  {
    path: "todos",
    loadChildren: () =>
      import("./termintabelle/termintabelle.module").then(
        (m) => m.TermintabellePageModule,
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
