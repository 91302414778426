// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-button {
  --background: none;
  --border-width: 0px;
  --box-shadow: 0px;
}

.kontaktdaten__modal-button {
  color: white;
}

.ion-button-container {
  display: flex;
  align-items: center;
  width: 29px;
  height: 29px;
}`, "",{"version":3,"sources":["webpack://./src/app/util/kontaktdaten/kontaktdaten.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".icon-button {\n  --background: none;\n  --border-width: 0px;\n  --box-shadow: 0px;\n}\n\n.kontaktdaten__modal-button {\n  color: white;\n}\n\n.ion-button-container {\n  display: flex;\n  align-items: center;\n  width: 29px;\n  height: 29px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
